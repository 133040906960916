<script setup>
const props = defineProps({
  src: {
    type: String,
    required: true
  },
  mobileSrc: {
    type: String,
    default: ''
  },
  alt: {
    type: String,
    default: ''
  },
  width: {
    type: Number,
    default: 0
  },
  height: {
    type: Number,
    default: 0
  },
  preload: {
    type: Boolean,
    default: false
  },
  loading: {
    type: String,
    default: ''
  },
  static: {
    type: Boolean,
    default: false
  }
})

const {alt, width, height, preload} = props;

const src = computed(() => {
  return props.src
})


const mobileSrc = computed(() => {
  return props.mobileSrc
})

if (preload) {
  useHead({
    link: [
      {rel: 'preload', href: src, as: 'image', media: '(min-width: 1024px)'},
      {rel: 'preload', href: mobileSrc, as: 'image', media: '(max-width: 1023px)'},
    ]
  })
}

</script>

<template>
  <picture :class="{static:static}">
    <source media="(min-width: 1024px)" :srcset="src">
    <source media="(max-width: 1023px)" v-if="mobileSrc" :srcset="mobileSrc">
    <img
        :loading="loading"
        :src="src"
        :alt="alt"
        :width="width"
        :height="height"
    />
  </picture>
</template>

<style scoped>
picture {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;

  &.static {
    position: static;
  }
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
